import { Injectable } from "@angular/core";
import { JobCardMasterCreationInput } from "@shared/models/job-card-master/job-card-master.model";
import { ProfileService } from "@shared/services";
import { Apollo } from "apollo-angular";
import { ToastrService } from "ngx-toastr";
import { JOB_CARD_MASTER_CREATION_MUTATION } from "@graphql/job-card-master/mutation/job-card-master-creation-mutation.gql";
import { Subject } from "rxjs";
import { JobidCard } from "@shared/models/jobid-card/jobid-card.model";

@Injectable({
  providedIn: "root",
})
export class JobCardMasterDetailService {
  private affiliate!: string;
  public jobCardMasterCreated$: Subject<JobidCard> = new Subject<JobidCard>();
  constructor(private apollo: Apollo, private profileService: ProfileService, private toastrService: ToastrService) {
    this.setAffiliate();
  }

  createJobCardMaster(jobCardMasterCreationInput: JobCardMasterCreationInput) {
    return this.apollo
      .mutate({
        mutation: JOB_CARD_MASTER_CREATION_MUTATION,
        variables: {
          affiliate: this.affiliate,
          input: jobCardMasterCreationInput,
        },
        errorPolicy: "all",
      })
      .subscribe(({ data, errors }) => {
        if (errors) {
          console.error("Error create JCM {} for affiliate {} ", JSON.stringify(errors), this.affiliate);
          this.toastrService.error(errors.toString(), "Error");
        } else {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          const jobCardMasterCreated = data.createJobCardMaster;
          this.jobCardMasterCreated$.next({ ...jobCardMasterCreated});
        }
      });
  }

  setAffiliate(): void {
    this.profileService.affiliate$.subscribe((affiliate) => {
      this.affiliate = affiliate.value;
    });
  }
}
