import {Injectable} from "@angular/core";
import {UntypedFormBuilder, Validators} from "@angular/forms";
import {CoreCrewAssistance, CoreCrewAssistanceInput} from "@shared/models/prerequisites/core-crew-assistance.model";
import {AbstractArrayFormService} from "../../abstract-array-form.service";

@Injectable({
  providedIn: "root",
})
export class CoreCrewAssistanceFormService extends AbstractArrayFormService {
  currentId!: string;
  jobCardId!: number;

  constructor(protected fb: UntypedFormBuilder) {
    super(fb);
  }

  get formArrayName() {
    return "items";
  }

  createFormGroup(coreCrewAssistance?: CoreCrewAssistance) {
    return this.fb.group({
      id: [coreCrewAssistance?.id],
      description: [coreCrewAssistance?.description, [Validators.required]],
      status: [coreCrewAssistance?.status],
      discipline: [coreCrewAssistance?.discipline, [Validators.required]],
      displayed: [coreCrewAssistance?.displayed, []],
      jobCardId: [this.jobCardId],
    });
  }

  createForm(isModeReadOnly: boolean, coreCrewAssistanceList?: CoreCrewAssistance[]) {
    if(this.form != undefined && !isModeReadOnly){
      return this.form;
    }

    if(isModeReadOnly || this.form == undefined){
      this.data = coreCrewAssistanceList;
      const isChecked =
        (coreCrewAssistanceList?.length !== 0 && coreCrewAssistanceList?.every((item) => item.displayed)) || false;

      this.form = this.fb.group({
        checked: [{ value: isChecked, disabled: isModeReadOnly }, []],
        items: this.setInitialData(coreCrewAssistanceList),
      });
    }
    this.clearFormOnUnchecked()
    return this.form;
  }

  getFormData(coreCrewAssistanceList: CoreCrewAssistance[]): CoreCrewAssistanceInput[] {
    if (!coreCrewAssistanceList) {
      return [];
    }

    if (!this.form) return coreCrewAssistanceList.map((coreCrewAssistance) => this.copy(coreCrewAssistance));

    let itemsInput: CoreCrewAssistanceInput[] = [];
    if (this.form?.value[this.formArrayName]?.length !== 0) {
      itemsInput = this.form?.value[this.formArrayName].filter((item: any) => {
        return !!(item.discipline || item.description || item.status);
      }).map((item: CoreCrewAssistance) => {
        const itemInput = this.copy(item);
        itemInput.displayed = this.form.controls.checked.value;
        return itemInput;
      });
    }

    return itemsInput;
  }

  private copy(coreCrewAssistance: CoreCrewAssistance): CoreCrewAssistanceInput {
    return {
      id: coreCrewAssistance.id,
      description: coreCrewAssistance.description,
      status: coreCrewAssistance.status,
      discipline: coreCrewAssistance.discipline,
      displayed: coreCrewAssistance.displayed,
      jobCardId: coreCrewAssistance.jobCardId,
    };
  }

  setJobCardId(jobCardId: number) {
    this.jobCardId = jobCardId;
  }
}
