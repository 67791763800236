import gql from "graphql-tag";

const JOB_CARD_MASTER_CREATION_MUTATION = gql`
  mutation createJobCardMaster($affiliate: String, $input: JobCardsMasterInput) {
    createJobCardMaster(affiliate: $affiliate, input: $input) {
      id
      title
      affiliate
      mainSite
      sites
      startDate
      endDate
      owner
      creationDate
      lastModified
      isCampaign
      generalStatus
      actualStatus
      budgets
      specificAccommodation
      isShutDownDuringCampaign
      shutDownDate
      shutDownDuration
      isScopeFrozen
      computedValues {
        currentShortfallProduction
        currentShortfallInjection
        currentShortfallFlaring
        currentBudget
        minStartDate
        maxEndDate
        hasJobCardStartingBeforeMinStartDate
        hasJobCardEndingAfterMaxEndDate
      }
    }
  }
`;

export { JOB_CARD_MASTER_CREATION_MUTATION };
