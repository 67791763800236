import {NgModule} from "@angular/core";
import {BrowserModule} from "@angular/platform-browser";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {HttpClientModule} from "@angular/common/http";
import {CoreModule} from "@core/core.module";
import {RoutesModule} from "@routes/routes.module";
import {SharedModule} from "@shared/shared.module";

import {AppComponent} from "./app.component";
import {MsalRedirectComponent} from "@azure/msal-angular";
import {GraphQLModule} from "@graphql/graphql.module";

const MODULES = [BrowserModule, BrowserAnimationsModule, HttpClientModule, CoreModule, RoutesModule, SharedModule];

@NgModule({
  declarations: [AppComponent],
  imports: [...MODULES, GraphQLModule, HttpClientModule, BrowserAnimationsModule],
  providers: [],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {}
