import { Injectable } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { filter, pairwise } from "rxjs/operators";
import { BehaviorSubject, Observable } from "rxjs";
import { JOB_ID_CARD_ROUTES_NAMES } from "@routes/jobid-card/jobid-card.routes";
import { APP_ROUTES_NAMES } from "@routes/routes";

@Injectable({
  providedIn: "root",
})
export class RouterEventsService {
  private _hasBeenSetUp = false;

  // This is important due to user browser if reload
  private _hasRoutedFromJobCardList = false;
  private _hasRoutedFromJobCardList$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    this._hasRoutedFromJobCardList,
  );
  hasRoutedFromJobCardList$: Observable<boolean> = this._hasRoutedFromJobCardList$.asObservable();

  constructor(private router: Router) { }

  setUpRouting(): void {
    if (this._hasBeenSetUp) return;

    this.router.events
      .pipe(
        filter((e) => e instanceof NavigationEnd),
        pairwise(), // check it's second route load,
      )
      .subscribe((results: any[]) => {
        const previousRoute: string = results[0].url;

        const hasRoutedFromJobCardList =
          previousRoute === "/" ||
          previousRoute === `/${APP_ROUTES_NAMES.JOB_ID_CARD}` ||
          previousRoute === `/${APP_ROUTES_NAMES.JOB_ID_CARD}/${JOB_ID_CARD_ROUTES_NAMES.LIST}`;
        this._hasRoutedFromJobCardList = hasRoutedFromJobCardList;
        this._hasRoutedFromJobCardList$.next(this._hasRoutedFromJobCardList);
      });
    this._hasBeenSetUp = true;
  }

  isJobCardListRoute(): boolean {
    return (
      this.router.url === "/" || this.router.url === `/${APP_ROUTES_NAMES.JOB_ID_CARD}` || this.router.url === `/${APP_ROUTES_NAMES.JOB_ID_CARD}/${JOB_ID_CARD_ROUTES_NAMES.LIST}`
    );
  }

  getCurrentUrl() {
    return this.router.url
  }
}
