export interface PaginatedResponse<T> {
  jobCardsAggregate: T[];
  totalItems: number;
  totalPages: number;
  currentPage: number;
}

export interface JobCardInfo {
  actualStatus: string;
  creationDate: string;
  discipline: string;
  endDate: string;
  fromSap: boolean;
  functionalLocation: string
  id: string
  isLeader: boolean;
  jobCardMasterId: string | null;
  jobCardMasterTitle: string | null;
  jobLeader: string;
  jobTitle: string;
  maximumPob: number;
  parentId: string | null;
  priority: string;
  revisionCode: string | null;
  sitePlatform: string;
  startDate: string;
  workOrderNumber: number;
}
