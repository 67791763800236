import {Injectable} from "@angular/core";
import {UntypedFormBuilder, Validators} from "@angular/forms";
import {AbstractArrayFormService} from "../../abstract-array-form.service";
import {Isolation, IsolationInput} from "@shared/models/prerequisites/isolation.model";

@Injectable({
  providedIn: "root",
})
export class IsolationFormService extends AbstractArrayFormService {
  currentId!: string;
  jobCardId!: number;

  constructor(protected fb: UntypedFormBuilder) {
    super(fb);
  }

  get formArrayName() {
    return "items";
  }

  createFormGroup(isolation?: Isolation) {
    return this.fb.group({
      id: [isolation?.id],
      description: [isolation?.description, [Validators.required]],
      status: [isolation?.status],
      isolationType: [isolation?.isolationType, [Validators.required]],
      displayed: [isolation?.displayed, []],
      jobCardId: [this.jobCardId],
    });
  }

  createForm(isDisabled: boolean, isolations?: Isolation[]) {
    if (this.form != undefined && !isDisabled) {
      return this.form;
    }

    if (isDisabled || this.form == undefined) {
      this.data = isolations;

      const isChecked = (isolations?.length !== 0 && isolations?.every((item) => item.displayed)) || false;

      this.form = this.fb.group({
        checked: [{value: isChecked, disabled: isDisabled}, []],
        items: this.setInitialData(isolations),
      });
    }
    this.clearFormOnUnchecked()
    return this.form;
  }

  getFormData(isolations: Isolation[]): IsolationInput[] {
    if (!isolations) {
      return [];
    }
    if (!this.form) return isolations.map((isolation) => this.copy(isolation));

    let itemsInput: IsolationInput[] = [];
    if (this.form?.value[this.formArrayName]?.length !== 0) {
      itemsInput = this.form?.value[this.formArrayName].filter((item: any) => {
        return item.isolationType || item.description || item.status;
      }).map((item: Isolation) => {
        const itemInput = this.copy(item);
        itemInput.displayed = this.form.controls.checked.value;
        return itemInput;
      });
    }

    return itemsInput;
  }

  private copy(isolation: Isolation): IsolationInput {
    return {
      id: isolation.id,
      description: isolation.description,
      status: isolation.status,
      isolationType: isolation.isolationType,
      displayed: isolation.displayed,
      jobCardId: isolation.jobCardId,
    };
  }

  setJobCardId(jobCardId: number) {
    this.jobCardId = jobCardId;
  }
}
