import { Injectable } from "@angular/core";
import {
  HTTP_INTERCEPTORS,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        const message = this.handleError(error);
        console.error("Error from HttpInterceptor ", message, error);
        return throwError(() => error);
      }),
    ) as Observable<HttpEvent<any>>;
  }

  private getServerErrorMessage(error: HttpErrorResponse): string {
    switch (error.status) {
      case 404: {
        return `Not Found: ${error.message}`;
      }

      case 403: {
        return `Access Denied: ${error.message}`;
      }

      case 500: {
        return `Internal Server Error: ${error.message}`;
      }

      default: {
        return `Unknown Server Error: ${error.message}`;
      }
    }
  }

  private handleError(error: HttpErrorResponse): string {
    let errorMessage = "";

    if (error instanceof HttpErrorResponse === false) {
      console.log("⛔️ Something else went wrong...");
    } else {
      errorMessage = `Error: ` + (error.error instanceof ErrorEvent ? `${error.message}` : `${this.getServerErrorMessage(error)}`);
    }

    return errorMessage;
  }
}

export const httpErrorInterceptorProvider = [
  { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
];
