<div class="container h-100">
  <div class="col my-5">
    <div class="card">
      <div class="card-body text-center">
        <div class="row">
          <div class="col-8">
            <img src="assets/403.png" alt="Error: Forbidden">
          </div>
          <div class="col-4 align-self-center">
            <h4>UNAUTHORIZED</h4>
            <p>We are sorry, but you do not have <br> access to this page or resource.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
