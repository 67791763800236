<div class="container-fluid p-3">
  <h4>{{'jobCardList.overview' | translate }}</h4>
  <div *ngIf="userHasAffiliates;else noAffiliateMessage" class="card wrapper">
    <div class="card-body">
        <div class="d-flex mb-4" id="tableActionBar">
          <!-- Tabs -->
          <jbid-activities-navbar [selectedTab]="JOBCARDS" class="me-auto"></jbid-activities-navbar>
          <!-- Buttons -->
          <div class="p-2 px-0 me-1">
            <button id="exportCsv" type="button" class="btn btn-sm btn-tertiary justify-content-center align-items-center"
                    (click)="exportToExcel()">
              <span class="material-icons">download</span>{{'jobCardList.exportCsv' | translate}}
            </button>
          </div>
          <div class="p-2 px-0">
            <div *ngIf="!_linkModeActive">
              <div *ngIf="environment.linkJobcardToJcmActive">
                <button id="active-link-jc2jcm-mode" type="button"
                        class="btn btn-sm btn-secondary  d-flex flex-row justify-content-center align-items-center"
                        (click)="jobCardMasterService.activeLinkMode(true)">
                  <span class="material-icons" aria-hidden="true">add_link</span>
                  {{'jobCardList.linkJobCards' | translate }}
                </button>
              </div>
            </div>
            <div *ngIf="_linkModeActive">
              <div >
                <button id="link-jc2jcm-cancel" type="button" class="btn btn-secondary  mx-2 d-flex flex-row justify-content-center align-items-center" (click)="jobCardMasterService.activeLinkMode(false)">
                  Cancel
                </button>
              </div>
            </div>
          </div>
          <div class="p-2 px-0">
            <div *ngIf="!_linkModeActive">
              <div [ngClass]="{'right': !environment.linkJobcardToJcmActive}"  >
                <button
                  id="add-new-jc"
                  type="button"
                  class="btn btn-sm btn-primary mx-2 d-flex flex-row justify-content-center align-items-center"
                  [disabled]="jobidCardDetailService.loadingMassiveCreation$ | async"
                  (click)="openJobCardModal()">
                  <span class="material-icons" aria-hidden="true">add</span>
                  {{'jobCardList.addNewJobCard' | translate}}
                </button>
              </div>
            </div>
            <div *ngIf="_linkModeActive">
              <div >
                <button [disabled]="selectedLines()<=0" id="link-jc2jcm-save" type="button" class="btn btn-primary  mx-2 d-flex flex-row justify-content-center align-items-center" (click)="openLinkToJobCardMasterModal()">
                  <span class="material-icons" aria-hidden="true">add_link</span>
                  {{ 'link ('+selectedLines()+')' }}
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- Job card table -->
        <jbid-job-card-table (jobCardClicked)="goToJobCardDetails($event)" (selectedLines)="updateSelectedLines($event)"></jbid-job-card-table>
    </div>
  </div>
</div>
<jbid-scroll-up-button></jbid-scroll-up-button>

<ng-template #noAffiliateMessage>
  <div class="container-fluid h-75">
    <h2 class="text-center">Sorry but it seems that you belong to any groups that has access to Job ID card, please
      contact support team or report it in Bug Report Teams channel</h2>
  </div>
</ng-template>
