import {ErrorHandler, Injectable} from "@angular/core";
import {NavigationEnd, Router} from "@angular/router";
import {AngularPlugin} from "@microsoft/applicationinsights-angularplugin-js";
import {ApplicationInsights} from "@microsoft/applicationinsights-web";
import {environment} from "@env/environment";
import {filter} from "rxjs/operators";

@Injectable({ providedIn: 'root' })
export class InsightsService {
  static readonly JBID_EVENT = 'JobCardEvent';
  static readonly JBID_EMAIL_EVENT = 'RoutedFromEmail';
  static readonly JBID_CREATION_EVENT = 'Created';
  static readonly JBID_UPDATED_EVENT = 'Updated';
  static readonly JBID_ACTIVATION_EVENT = 'Activated';
  static readonly JBID_COMPLETION_EVENT = 'Completed';
  static readonly JBID_CANCELLATION_EVENT = 'Cancelled';
  static readonly JBID_VALIDATION_EVENT = 'Validated for 2WF';

  private readonly angularPlugin = new AngularPlugin();
  private readonly instance: ApplicationInsights;
  private affiliates: string[] = [];
  private userRoles: string[] = [];
  private department = "";

  constructor(private readonly router: Router) {
    this.instance = this.createAppInsightsInstance();
    this.initializeAppInsights()
  }

  createAppInsightsInstance(): ApplicationInsights {
    return new ApplicationInsights({
      config: {
        instrumentationKey: environment.instrumentationKey,
        enableCorsCorrelation: true,
        enableAutoRouteTracking: false, // done by plugin by default
        extensions: [this.angularPlugin],
        extensionConfig: {
          [this.angularPlugin.identifier]: {
            errorServices: [new ErrorHandler()],
          },
        },
      },
    });
  }

  initializeAppInsights(): void {
    this.instance.loadAppInsights();
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event) => {
      const navigationEndEvent = event as NavigationEnd;
      const name = navigationEndEvent.url.split("?")[0].split("/").pop();
      this.logPageView(name, navigationEndEvent.url)
    });
  }

  logPageView(name?: string, url?: string) {
    // option to call manually
    this.instance.trackPageView({
      name: name,
      uri: url,
      properties: {
        "roles" : this.userRoles,
        "affiliates": this.affiliates,
        "department": this.department
      }
    });
  }

  logEvent(name: string, properties?: { [key: string]: any }) {
    this.instance.trackEvent({ name: name }, properties);
  }

  logMetric(name: string, average: number, properties?: { [key: string]: any }) {
    this.instance.trackMetric({ name: name, average: average }, properties);
  }

  logException(exception: Error, severityLevel?: number) {
    this.instance.trackException({ exception: exception, severityLevel: severityLevel });
  }

  logTrace(message: string, properties?: { [key: string]: any }) {
    this.instance.trackTrace({ message: message }, properties);
  }

  setUserId(authUserId: string | undefined, userId: string | undefined): void {
    if (authUserId) {
      this.instance.setAuthenticatedUserContext(authUserId, userId, true);
    }
  }

  setUserRoles(userRoles: string[]) {
    this.userRoles = userRoles;
  }

  setAffiliates(affiliates: string[]) {
    this.affiliates = affiliates;
  }

  setDepartment(department: string) {
    this.department = department;
  }

}

// https://timdeschryver.dev/blog/configuring-azure-application-insights-in-an-angular-application#helpful-stack-traces-in-application-insights
