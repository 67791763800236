import {Injectable} from "@angular/core";
import {UntypedFormBuilder, Validators} from "@angular/forms";
import {AbstractArrayFormService} from "../../abstract-array-form.service";
import {TemporaryEquipment, TemporaryEquipmentInput} from "@shared/models/prerequisites/temporary-equipment.model";

@Injectable({
  providedIn: "root",
})
export class TemporaryEquipmentFormService extends AbstractArrayFormService {
  currentId!: string;
  jobCardId!: number;

  constructor(protected fb: UntypedFormBuilder) {
    super(fb);
  }

  get formArrayName() {
    return "items";
  }

  createFormGroup(temporaryEquipment?: TemporaryEquipment) {
    return this.fb.group({
      id: [temporaryEquipment?.id],
      description: [temporaryEquipment?.description, [Validators.required]],
      status: [temporaryEquipment?.status],
      displayed: [temporaryEquipment?.displayed, []],
      jobCardId: [temporaryEquipment?.jobCardId],
    });
  }

  createForm(isModeReadOnly: boolean, temporaryEquipments?: TemporaryEquipment[]) {
    if(this.form != undefined && !isModeReadOnly){
      return this.form;
    }

    if(isModeReadOnly || this.form == undefined){
      this.data = temporaryEquipments;

      const isChecked =
        (temporaryEquipments?.length !== 0 && temporaryEquipments?.every((item) => item.displayed)) || false;

      this.form = this.fb.group({
        checked: [{ value: isChecked, disabled: isModeReadOnly }, []],
        items: this.setInitialData(temporaryEquipments),
      });
    }
    this.clearFormOnUnchecked()
    return this.form;
  }

  getFormData(temporaryEquipments: TemporaryEquipment[]): TemporaryEquipmentInput[] {
    if (!temporaryEquipments) {
      return [];
    }
    if (!this.form) return temporaryEquipments.map((temporaryEquipment) => this.copy(temporaryEquipment));

    let itemsInput: TemporaryEquipmentInput[] = [];
    if (this.form?.value[this.formArrayName]?.length !== 0) {
      itemsInput = this.form?.value[this.formArrayName].filter((item: any) => {
        return item.description || item.status;
      }).map((item: TemporaryEquipment) => {
        const itemInput = this.copy(item);
        itemInput.jobCardId = this.jobCardId;
        itemInput.displayed = this.form.controls.checked.value;
        return itemInput;
      });
    }

    return itemsInput;
  }

  private copy(temporaryEquipment: TemporaryEquipment): TemporaryEquipmentInput {
    return {
      id: temporaryEquipment.id,
      description: temporaryEquipment.description,
      status: temporaryEquipment.status,
      displayed: temporaryEquipment.displayed,
      jobCardId: temporaryEquipment.jobCardId,
    };
  }

  setJobCardId(jobCardId: number) {
    this.jobCardId = jobCardId;
  }
}
