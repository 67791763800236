import { Component, Input, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import {
  DocumentLinkModalFormService
} from "@shared/components/modal/document-link-modal/document-link-modal-form.service";
import {
  DocumentDTO
} from "@shared/models/prerequisites/document-dto.model";

@Component({
  selector: "jbid-document-link-modal",
  templateUrl: "./document-link-modal.component.html",
  styleUrls: ["./document-link-modal.component.scss"],
})
export class DocumentLinkModalComponent implements OnInit {

  @Input()
  documents!: DocumentDTO[];

  @Input()
  documentType!: string;

  @Input()
  section!: string;

  @Input()
  sectionIndex = 1;

  @Input()
  isModeReadOnly$: any;

  readonly = true;

  constructor(public activeModal: NgbActiveModal, public documentLinkModalFormService: DocumentLinkModalFormService){}

  ngOnInit(): void{
    this.documents = this.documents ?? [];
    this.isModeReadOnly$.subscribe((isReadOnly: boolean) => {
      this.readonly = isReadOnly;
    });
  }

  addNewDocumentLink(){
    this.documents.push(this.documentLinkModalFormService.getNewDocument(this.section, this.documentType, this.sectionIndex));
    this.documentLinkModalFormService.resetInput();
  }

  removeLink(i: number){
    this.documentLinkModalFormService.removeLink(i, this.documents);
  }

  getDocumentLinkNumber(docLinkList: DocumentDTO[]) {
    let docLinkNumber = 0
    if(docLinkList.length != 0){
      for (const element of docLinkList){
        if(element.sectionIndex  != null && element.sectionIndex != -1 ){
          docLinkNumber += 1
        }
      }
    }
    return docLinkNumber;
  }
}
