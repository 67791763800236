import gql from "graphql-tag";

const GET_JOB_CARD_LIST_QUERY = gql`
  query ($affiliate: String, $page: Int, $size: Int, $sortBy: String!, $direction: String!, $filter: [Filter]) {
    jobCardAggregated(
      affiliate: $affiliate
      page: $page
      size: $size
      sortBy: $sortBy
      direction: $direction
      filter: $filter
    ) {
      jobCardsAggregate {
        id
        jobTitle
        workOrderNumber
        priority
        sitePlatform
        generalStatus
        actualStatus
        potentialReadiness
        startDate
        endDate
        owner
        discipline
        revisionCode
        masterNotification
        workOrderUserStatus
        workOrderSystemStatus
        functionalLocation
        functionalLocationDescription
        attributes
        dgsValue
        lotiValue
        workOrderType
        maximumPob
        fromSap
        jobLeader
        creationDate
        masterClassification
        jobCardMasterId
        jobCardMasterTitle
        isLeader
        parentId
        days
      }
      currentPage
      totalPages
      totalItems
    }
  }
`;

export { GET_JOB_CARD_LIST_QUERY };
