import {Injectable} from "@angular/core";
import {UntypedFormBuilder, Validators} from "@angular/forms";
import {ResourceInvitation, ResourceInvitationInput} from "@shared/models/prerequisites/resource-invitation.model";
import {AbstractArrayFormService} from "@routes/jobid-card/detail/prerequisites/abstract-array-form.service";

@Injectable({
  providedIn: "root",
})
export class LettersOfInvitationFormService extends AbstractArrayFormService {
  jobCardId!: number;

  constructor(protected fb: UntypedFormBuilder) {
    super(fb);
  }

  private setUpOnChanges() {
    this.getControl("displayed")?.valueChanges.subscribe((value) => {
      if (value) {
        this.getControl("status")?.setValidators([Validators.required]);
        this.getControl("invitationVisaStatus")?.setValidators([Validators.required]);
        this.getControl("description")?.setValidators([Validators.required]);
      } else {
        this.getControl("status")?.setValidators(null);
        this.getControl("status")?.patchValue(null);
        this.getControl("invitationVisaStatus")?.setValidators(null);
        this.getControl("invitationVisaStatus")?.patchValue(null);
        this.getControl("description")?.setValidators(null);
        this.getControl("description")?.patchValue(null);
      }
    });
  }


  getFormData(invitationsResources: ResourceInvitation[]): ResourceInvitationInput[] {
    if (!invitationsResources) {
      return [];
    }
    if (!this.form) return invitationsResources.map((invitation) => this.copy(invitation));

    let itemsInput: ResourceInvitationInput[] = [];
    if (this.form?.value[this.formArrayName]?.length !== 0) {
      itemsInput = this.form?.value[this.formArrayName].filter((item: any) => {
        return item.invitationVisaStatus || item.description || item.status || item.internationalFlights;
      }).map((item: ResourceInvitation) => {
        const itemInput = this.copy(item);
        itemInput.displayed = this.form.controls.checked.value;
        return itemInput;
      });
    }

    return itemsInput;
  }

  private copy(resources: ResourceInvitation): ResourceInvitationInput {
    return {
      id: resources.id,
      displayed: resources.displayed,
      status: resources.status,
      invitationVisaStatus: resources.invitationVisaStatus,
      internationalFlights: resources.internationalFlights,
      description: resources.description,
      jobCardId: resources.jobCardId,
    };
  }

  createFormGroup(invitation?: ResourceInvitation) {
    return this.fb.group({
      id: [invitation?.id],
      description: [invitation?.description, [Validators.required]],
      status: [invitation?.status],
      invitationVisaStatus: [invitation?.invitationVisaStatus, [Validators.required]],
      internationalFlights: [invitation?.internationalFlights, [Validators.required]],
      displayed: [invitation?.displayed, []],
      jobCardId: [this.jobCardId],
    });
  }

  createForm(isDisabled: boolean, invitations?: ResourceInvitation[]) {
    if (this.form != undefined && !isDisabled) {
      return this.form;
    }

    if (isDisabled || this.form == undefined) {
      this.data = invitations;

      const isChecked = (invitations?.length !== 0 && invitations?.every((item) => item.displayed)) || false;

      this.form = this.fb.group({
        checked: [{value: isChecked, disabled: isDisabled}, []],
        items: this.setInitialData(invitations),
      });
    }
    this.clearFormOnUnchecked()
    return this.form;
  }

  get formArrayName() {
    return "items";
  }

  setJobCardId(jobCardId: number) {
    this.jobCardId = jobCardId;
  }
}
