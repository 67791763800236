import { Injectable } from "@angular/core";

import { Apollo } from "apollo-angular";
import { GET_JOB_CARD_LIST_QUERY } from '../../../../graphql/jobid-card/queries/jobid-card-list.gql';
import { Observable, map } from "rxjs";
import { JobCardInfo, PaginatedResponse } from '../../../../shared/models/response.model';

@Injectable({
  providedIn: "root",
})
export class JobidCardService {
  readonly DEFAULT_SORT_COLUMN = "id";
  readonly DEFAULT_SORT_DIRECTION = "desc";

  constructor(private apollo: Apollo) {}

  readJobCards(affiliate: string, page: number, pageSize: number, filter: any[]): Observable<PaginatedResponse<JobCardInfo>> {
    return this.apollo
      .query<any>({
        query: GET_JOB_CARD_LIST_QUERY,
        variables: {
          affiliate: affiliate,
          page: Number(page),
          size: Number(pageSize),
          sortBy: this.DEFAULT_SORT_COLUMN,
          direction: this.DEFAULT_SORT_DIRECTION,
          filter: filter,
        },
      }).pipe(map((result) => result.data.jobCardAggregated));
  }
}
