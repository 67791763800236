import {Component, OnDestroy, OnInit} from "@angular/core";
import {GeneralStatus, JobGateReadiness, mGeneralStatus} from "@shared/models/jobid-card/jobid-card.model";
import {ConnectionPositionPair} from "@angular/cdk/overlay";
import {JobidCardDetailService} from "@shared/services/jobid-card-detail/jobid-card-detail.service";
import {AuthService} from "@shared/services";
import {keyDescOrderComparator} from "@shared/models";
import {Subject, takeUntil} from "rxjs";

@Component({
  selector: "jbid-timeline",
  templateUrl: "./timeline.component.html",
  styleUrls: ["./timeline.component.scss"],
})
export class TimelineComponent implements OnInit, OnDestroy {
  jobCardDetail: any;
  isReadyFor2WF = false;
  disabled2WFActivation = false;
  pendingActivation = false;
  userName = "";
  destroy = new Subject<void>();

  isModeReadOnly$ = this.jobCardDetailService.isModeReadOnly$;

  selectedStatus!: GeneralStatus;
  creationDate!: Date;
  startDate!: Date;
  isDraft = false;

  mGeneralStatusEnum = mGeneralStatus;
  isInfoOverlayOpen = false;
  isAlertOverlayOpen = false;
  isActivationDisableOverlayOpen = false;
  isCancelDisableOverlayOpen = false;
  isDraftDisableOverlayOpen = false;
  isWarningOverlayOpen = false;
  isLegendsOverlayOpen = false;
  triggerAlertOrigin: any;
  triggerWarningOrigin: any;
  triggerLegends: any;
  triggerActivationDisable: any;
  triggerCancelDisable: any;
  triggerDraftDisable: any;
  titleOverlayPositionPairs: ConnectionPositionPair[] = [
    {
      offsetX: 20,
      offsetY: -35,
      originX: "start",
      originY: "bottom",
      overlayX: "start",
      overlayY: "bottom",
    },
  ];
  private readonly jobPlannerRole = "JobPlanners";
  hasJobPlannerRole = false;
  keyDescOrder = keyDescOrderComparator;
  isJobLeaderOrActivityOwner = false;
  isAwaiting2WFValidation = false

  constructor(private readonly jobCardDetailService: JobidCardDetailService, private readonly authService: AuthService) {
    this.authService.userRoles$.pipe(takeUntil(this.destroy)).subscribe((roles) => {
      this.hasJobPlannerRole = roles?.includes(this.jobPlannerRole) || false;
    });

    this.authService.userName$.pipe(takeUntil(this.destroy)).subscribe((userName) => {
      this.userName = userName;
      this.checkIsJobLeaderOrActivityOwner();
    })
  }

  ngOnInit(): void {
    this.jobCardDetailService.isReadyFor2WF$.pipe(takeUntil(this.destroy)).subscribe((isReadyFor2WF) => {
      this.isReadyFor2WF = isReadyFor2WF;
    });
    this.jobCardDetailService.generalStatusBackUp$.pipe(takeUntil(this.destroy)).subscribe((status) => {
      this.selectedStatus = status;
      this.isDraft = JobidCardDetailService.isGeneralStatusDraftOrReadyToActivate(status);
    });
    this.jobCardDetailService.generalStatus$.pipe(takeUntil(this.destroy)).subscribe((status) => {
      this.selectedStatus = status;
      this.isDraft = JobidCardDetailService.isGeneralStatusDraftOrReadyToActivate(status);
    });
    this.jobCardDetailService.generalDetails$.pipe(takeUntil(this.destroy)).subscribe((generalDetails) => {
      this.jobCardDetail = generalDetails;
      this.startDate = generalDetails.startDate;
      this.creationDate = generalDetails.creationDate;
      this.isReadyFor2WF = generalDetails.twoWeeksFrozenValidated;
      this.pendingActivation = generalDetails.activationPending;
      this.isAwaiting2WFValidation = generalDetails.actualStatus === JobGateReadiness.AWAITING_PREP_2WF;
      this.checkIsReadyFor2WF(generalDetails.twoWeeksFrozenValidated, generalDetails.generalStatus, generalDetails.activityOwner);
      this.checkIsJobLeaderOrActivityOwner();
    });
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }


  isStatus(status: GeneralStatus | string) {
    return JobidCardDetailService.matchGeneralStatusByString(this.selectedStatus, status);
  }

  showFirstJobCursor(readiness: string, gate: string): boolean {
    if (!this.isStatus(GeneralStatus.ACTIVE) && !this.isStatus(GeneralStatus.CANCELLED)) {
      return false;
    }
    if (!this.jobCardDetail.actualStatus) {
      return false;
    }
    return (
      this.jobCardDetail.actualStatus.includes(gate + " " + readiness) ||
      this.jobCardDetail.actualStatus.includes(gate + " Alert " + readiness)
    );
  }

  showSecondJobCursor(readiness: string, gate: string): boolean {
    if (!this.isStatus(GeneralStatus.ACTIVE) && !this.isStatus(GeneralStatus.CANCELLED)) {
      return false;
    }
    if (this.isStatus(GeneralStatus.CANCELLED)) {
      return false;
    }
    if (!this.jobCardDetail.potentialReadiness) {
      return false;
    }
    return this.jobCardDetail.potentialReadiness.includes(gate + " " + readiness);
  }

  progressBarCssClass(progress: string) {
    if (this.isStatus(GeneralStatus.CLOSED)) {
      return "empty-progress empty-buffer";
    }

    let result = progress;
    if (this.jobCardDetail.actualStatus && this.isStatus(GeneralStatus.CANCELLED)) {
      if (
        this.readinessToWeight(this.jobCardDetail.actualStatus) <
        this.progressToWeight(progress)
      ) {
        result = result + " empty-buffer";
      }
      if (
        this.readinessToWeight(this.jobCardDetail.actualStatus) + 1 <
        this.progressToWeight(progress)
      ) {
        result = result + " empty-progress";
      }
    }
    return result;
  }

  cancelledCursorCssClass(): string {
    return this.isStatus(GeneralStatus.CANCELLED) ? "red" : "";
  }

  progressToWeight(progress: string): number {
    switch (progress) {
      case "gray-progress":
        return 0;
      case "orange-progress":
        return 2;
      case "blue-progress":
        return 4;
      case "violet-progress":
        return 6;
      default:
        return 8;
    }
  }

  readinessToWeight(readiness: string): number {
    switch (readiness) {
      case JobGateReadiness.DRAFT:
        return 0;
      case JobGateReadiness.AWAITING_PREP_4M:
        return 1;
      case JobGateReadiness.READY_4M:
        return 2;
      case JobGateReadiness.AWAITING_PREP_5W:
      case JobGateReadiness.AWAITING_PREP_5W_ALERT:
        return 3;
      case JobGateReadiness.READY_5W:
        return 4;
      case JobGateReadiness.AWAITING_PREP_2WF:
        return 5;
      case JobGateReadiness.READY_2WF:
        return 6;
      default:
        return 7;
    }
  }

  onGeneralStatusChange(status: string) {
    this.jobCardDetailService.onGeneralStatusChange(status);
  }

  isActualStatusLowerThanJobReadiness(): boolean {
    if (
      !this.jobCardDetail.actualStatus ||
      !this.jobCardDetail.potentialReadiness
    ) {
      return false;
    }
    return (
      this.readinessToWeight(this.jobCardDetail.actualStatus) >
      this.readinessToWeight(this.jobCardDetail.potentialReadiness)
    );
  }

  isAlertActualStatus(): boolean {
    if (!this.jobCardDetail.actualStatus) {
      return false;
    }
    return (
      this.jobCardDetail.actualStatus === JobGateReadiness.AWAITING_PREP_5W_ALERT
    );
  }

  private toggleAlert(trigger: any) {
    this.triggerAlertOrigin = trigger;
    this.isAlertOverlayOpen = true;
  }

  private toggleWarning(trigger: any) {
    this.triggerWarningOrigin = trigger;
    this.isWarningOverlayOpen = true;
  }

  toggle(triggerWarning: any, triggerAlert: any) {
    if (this.isActualStatusLowerThanJobReadiness()) {
      this.toggleWarning(triggerWarning);
    } else if (this.isAlertActualStatus()) {
      this.toggleAlert(triggerAlert);
    }
  }

  toggleDisableActivation(trigger: any) {
    this.triggerActivationDisable = trigger;
    this.isActivationDisableOverlayOpen = true;
  }

  checkActivationRole(generalStatus: any) {
    return !this.hasJobPlannerRole &&
      JobidCardDetailService.matchGeneralStatusByString(generalStatus, GeneralStatus.ACTIVE);
  }

  toggleDisableDraft(trigger: any) {
    this.triggerDraftDisable = trigger;
    this.isDraftDisableOverlayOpen = true;
  }

  toggleDisableCancel(trigger: any) {
    this.triggerCancelDisable = trigger;
    this.isCancelDisableOverlayOpen = true;
  }

  isActivationStatus(generalStatus: any) {
    return this.isStatus(GeneralStatus.ACTIVE) &&
      JobidCardDetailService.isGeneralStatusDraftOrReadyToActivate(generalStatus);
  }

  validateTwoWeeksFrozenGate() {
    if (!this.isReadyFor2WF) {
      this.jobCardDetailService.handle2wfGateActivation()
    }
  }

  checkIsReadyFor2WF(twoWeeksFrozenValidated: boolean, generalStatus: GeneralStatus, activityOwner: string) {
    this.disabled2WFActivation = (twoWeeksFrozenValidated || generalStatus !== GeneralStatus.ACTIVE || this.userName !== activityOwner);
  }

  private checkIsJobLeaderOrActivityOwner() {
    if (this.jobCardDetail) {
      this.isJobLeaderOrActivityOwner = this.jobCardDetail.activityOwner === this.userName || this.jobCardDetail.jobLeader === this.userName;
    }
  }

  isLinkedAndStatusIsCancelled(generalStatus: string) {
    return JobidCardDetailService.matchGeneralStatusByString(generalStatus, GeneralStatus.CANCELLED)
    && this.jobCardDetail.jobCardMasterId !== null;
  }
}
