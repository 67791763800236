<ul class="nav nav-tabs nav-underline">
  <!-- Job card list -->
  <li class="nav-item">
    <button id="jobCardListTab" class="nav-link" [class.active]="selectedTab() === JOBCARDS" type="button" (click)="onTabChange(JOBCARDS)">
      <div class="d-flex align-items-center">
        <span>{{'jobCardList.jobCardList' | translate}}</span>
      </div>
    </button>
  </li>
  <li class="nav-item">
    <button id="jobCardMasterTab" class="nav-link" [class.active]="selectedTab() === JOBCARDMASTER" type="button" (click)="onTabChange(JOBCARDMASTER)">
      <div class="d-flex align-items-center">
        <span>{{'jobCardList.jobCardMaster' | translate}}</span>
      </div>
    </button>
  </li>
  <li class="nav-item">
    <button id="breakInsTab" class="nav-link disabled" type="button">
      <div class="d-flex align-items-center">
        <span>{{'jobCardList.breakIns' | translate}}</span>
      </div>
    </button>
  </li>
</ul>
