import { GeneralStatus } from "./jobid-card.model";

export interface JobCardGeneralDetailsAggregate {
  id: number;
  jobTitle: string;
  workOrderNumber: number;
  priority: string;
  description: string;
  generalStatus: GeneralStatus;
  startDate: Date;
  endDate: Date;
  attributes: string;
  seceFromSap: boolean;
  dgsValue: string;
  lotiValue: string;
  sitePlatform: string;
  metier: string;
  disciplineCategory: string;
  discipline: string;
  revisionCode: string;
  masterNotification: string;
  functionalLocation: string;
  functionalLocationDescription: string;
  activityType: string;
  shiftType: string;
  jobLeader: string;
  jobEstimatedCost: number;
  maximumPob: number;
  fromSap: boolean;
  siteVisitOnly: boolean;
  actualStatus?: string;
  potentialReadiness?: string;
  activityId: string;
  projectId: string;
  projectWbsGroupCode: string;
  wbsObjectId: string;
  creationDate: Date;
  wbsElement: string;
  wbsDescription: string;
  lastUpdateReadiness: number;
  lastSapSyncDate: number;
  flocPlatform: string;
  activityOwner: string;
  jobCardMasterId?: number;
  jobCardMasterTitle?: string;
  twoWeeksFrozenValidated: boolean;
  isLeader?: boolean;
  parentId?: number;
  parentTitle?: string;
  activationPending: boolean;
  prerequisites?: any;
}

export interface JobCardGeneralDetailsAggregateInput {
  id: number;
  jobTitle: string;
  workOrderNumber: number;
  priority: string;
  description: string;
  generalStatus: GeneralStatus;
  startDate: Date;
  endDate: Date;
  attributes: string;
  dgsValue: string;
  lotiValue: string;
  sitePlatform: string;
  metier: string;
  disciplineCategory: string;
  discipline: string;
  revisionCode: string;
  masterNotification: string;
  functionalLocation: string;
  functionalLocationDescription: string;
  activityType: string;
  shiftType: string;
  jobLeader: string;
  jobEstimatedCost: number;
  maximumPob: number;
  fromSap: boolean;
  siteVisitOnly: boolean;
  activityId: string;
  projectId: string;
  wbsObjectId: string;
  wbsElement: string;
  wbsDescription: string;
  flocPlatform: string;
  activityOwner: string;
  twoWeeksFrozenValidated: boolean;
  activationPending: boolean;
}

export const aActivityType = [
  { name: "BK", value: "BK - Breakdown, corrective" },
  { name: "DE", value: "DE- After Default" },
  { name: "PC", value: "PC-Preventive on condition" },
  { name: "CN", value: "CN - After construction / modification" },
  { name: "EX", value: "EX - Externals / Others" },
  { name: "FO", value: "FO - For Operations" },
  { name: "MD", value: "MD - Modifications" },
  { name: "MM", value: "MM - Major Modifications" },
  { name: "MS", value: "MS - General Maintenance Support" },
  { name: "SP", value: "SP - Special" },
  { name: "BL", value: "BL - Baseline" },
  { name: "PS", value: "PS - Preventive Scheduled" },
  { name: "RO", value: "RO - Routine" },
  { name: "ST", value: "ST - Statutory" },
];

export const aActivityTypeTepdk = [...aActivityType, { name: "SO", value: "SO  - software/hardware changes" }];
