import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";
import { CustomDateParserFormatter } from "./datepicker-adapter";

export class CustomValidators {
  static readonly dateParserFormatter = new CustomDateParserFormatter();

  static startDateAfterEndDate(startDateControlName: string, endDateControlName: string,
    errorName: string = "startDateAfterEndDate"): ValidatorFn{
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control) {
        return null;
      }

      const start = control.get(startDateControlName);
      const end = control.get(endDateControlName);
      if (!start?.value || !end?.value) {
        return null;
      }

      const startDate = CustomValidators.dateParserFormatter.parse(new Date(start.value).toString());
      const endDate = CustomValidators.dateParserFormatter.parse(new Date(end.value).toString());
      const areObjectValuesNonNull = (obj: any) => Object.values(obj).every((value: any) => value !== null);

      if (startDate && areObjectValuesNonNull(startDate) &&
        endDate && areObjectValuesNonNull(endDate) && startDate.after(endDate)) {
        return { [errorName]: true };
      }
      return null;
    };
  }

  static dateFromOrDateToNull(startDateControlName: string, endDateControlName: string,
    errorName: string = "dateFromOrDateToNull"): ValidatorFn{
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control) {
        return null;
      }

      const start = control.get(startDateControlName);
      const end = control.get(endDateControlName);
      if ((start?.value && !end?.value) || (!start?.value && end?.value)) {
        return { [errorName]: true };
      }

      return null;
    };
  }
}
