import { Component, input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';

@Component({
  selector: 'jbid-activities-navbar',
  standalone: true,
  imports: [CommonModule, TranslateModule, RouterModule],
  templateUrl: './activities-navbar.component.html'
})
export class ActivitiesNavbarComponent {

  selectedTab = input(Tab.JOBCARDS);

  readonly JOBCARDS = Tab.JOBCARDS;
  readonly JOBCARDMASTER = Tab.JOBCARDMASTER;


  constructor(private router: Router, private route: ActivatedRoute) {}

  onTabChange(tabName: Tab) {
    this.router.navigate([tabName], {relativeTo: this.route});
  }
}

export enum Tab {
  JOBCARDS = `../../jobid-card/list`,
  JOBCARDMASTER = `../../job-card-master/list`,
  BREAKINS = ''
}
