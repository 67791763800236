<form *ngIf="jobidCardGroupForm" [formGroup]="jobidCardGroupForm" #formRef="ngForm"
      [ngClass]="{ 'needs-validation': formRef.submitted }" (ngSubmit)="onSubmit()">
    <div class="modal-header">
        <div class="d-flex flex-row modal-title">
            <span class="material-icons-outlined text-primary" aria-hidden="true">create</span>
            <h5 class="modal-title">{{'createPopup.createJobCard' | translate}}</h5>
        </div>
        <button type="button" class="btn btn-close" aria-label="Close"
                (click)="activeModal.dismiss('Cross click')"></button>
    </div>

    <div class="modal-body">
        <div class="container">

          <fieldset class="row d-flex py-2 m-lg-2">
            <legend class="form-label">
              {{'createPopup.massiveUpload' | translate}}
            </legend>
              <div class="form-group d-flex flex-row">
                <div class="form-check col-3">
                  <input
                    class="form-check-input"
                    type="radio"
                    [checked]="(massiveUpload | async) === true"
                    [value]="true"
                    (change)="changeMassiveUploadState(true)"
                    id="massiveUploadYes"
                  />
                  <label class="form-check-label fw-bold" for="massiveUploadYes">{{'createPopup.yes' | translate}}</label>
                </div>
                <div class="form-check col-3">
                  <input
                    class="form-check-input"
                    type="radio"
                    [checked]="(massiveUpload | async) === false"
                    [value]="false"
                    (change)="changeMassiveUploadState(false)"
                    id="massiveUploadNo"
                  />
                  <label class="form-check-label fw-bold" for="massiveUploadNo">{{'createPopup.no' | translate}}</label>
                </div>
            </div>
          </fieldset>

            <div *ngIf="(massiveUpload | async) === false">
              <div *ngIf="existingJobCardId" class="alert alert-dismissible row d-flex white-bg">
                <div class="col-1">
                  <span class="text-danger material-icons">report_problem</span>
                </div>
                <div class="text-danger col-11">
                  This work order already exists. You can access to this job by clicking on the redirection link below
                </div>
                <div class="col-md-12 text-right">
                  <button id="existingJobCardButton" type="button" (click)="goToExistingJobCard()" class="alert-link btn btn-link arrow-primary">
                    Access to this job card
                  </button>
                </div>
              </div>

              <div class="row d-flex py-2 m-lg-2">
                <div class="col-9">
                  <div class="form-group">
                    <label class="form-label" for="workOrder">{{'createPopup.sapWorkOrder' | translate}}</label>
                    <span class="form-placeholder"> ({{'createPopup.informWO' |translate}})</span>
                    <input id="workOrder" type="text" formControlName="workOrder" limit="getWoDigitMax()"
                           class="form-control"
                           [ngClass]="{ 'is-invalid': checkWorkOrderValidation() }"/>
                    <div class="invalid-feedback" *ngIf="f.workOrder.invalid && f.workOrder.dirty">
                      <span class="material-icons">error_outline</span>
                      {{'createPopup.woDigits' | translate}} {{ this.getWoDigitMax }} {{'createPopup.digits' | translate}}
                    </div>
                    <div class="invalid-feedback"
                         *ngIf="existingJobCardId && !(f.workOrder.invalid && f.workOrder.dirty)">
                      <span class="material-icons">error_outline</span>
                      Work order already associated
                    </div>
                    <div class="invalid-feedback"
                         *ngIf="woNotExisting && !(f.workOrder.invalid && f.workOrder.dirty)">
                      <span class="material-icons">error_outline</span>
                      {{'createPopup.woNotExist' | translate}}
                    </div>
                  </div>
                </div>
              </div>

              <div class="row d-flex py-2 m-lg-2">
                <div class="col-9">
                  <div class="form-group">
                    <label class="form-label" for="site">
                      <span class="form-mandatory">*</span>
                      {{'createPopup.site' | translate}}
                    </label>
                    <span class="form-placeholder"> ({{'createPopup.informSite' | translate}})</span>
                    <input id="site" type="text" class="form-control" formControlName="site"
                           [ngClass]="{ 'is-invalid': f.site.invalid && f.site.dirty }"
                           [ngbTypeahead]="search" [resultFormatter]="formatter"/>
                  </div>
                </div>
              </div>
              <div class="row d-flex py-2 m-lg-2">
                <div class="col-9">
                    <div class="form-group">
                        <label class="form-label" for="floc-platform-input">
                            {{'createPopup.platform' | translate}}
                        </label>
                        <span class="form-placeholder"> ({{'createPopup.informPlatform' | translate}})</span>
                        <input id="floc-platform-input" type="text" class="form-control" formControlName="flocPlatform"
                               [ngClass]="{ 'is-invalid': f.flocPlatform.invalid && f.flocPlatform.dirty }"
                               [ngbTypeahead]="searchFlocPlatform" [resultFormatter]="formatter"
                        />
                    </div>
                </div>
            </div>

              <div class="row d-flex py-2 m-lg-2">
                <div class="col-9">
                  <div class="form-group">
                    <label class="form-label" for="jobTitle">
                      <span class="form-mandatory">*</span>
                      {{'createPopup.jobCArdName' | translate}}
                    </label>
                    <span class="form-placeholder"> ({{'createPopup.title' | translate}})</span>
                    <input id="jobTitle" type="text" formControlName="jobTitle" class="form-control"
                           [ngClass]="{ 'is-invalid': f.jobTitle.invalid && f.jobTitle.dirty }"/>
                  </div>
                </div>
              </div>

              <div class="row d-flex m-lg-1">
                <div class="col-9">
                  <div class="form-group">
                    <div class="form-check">
                      <label class="form-check-label" for="siteVisitOnly">
                         {{'createPopup.siteVisitOnly' | translate}}
                      </label>
                      <input class="form-check-input" type="checkbox" (change)="changeSapWorkOrderState()"
                      formControlName="siteVisitOnly" id="siteVisitOnly"/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="(massiveUpload | async) === true">
              <div class="row d-flex py-2 m-lg-2">
                <div class="col-10">
                  <div class="form-group">
                    <label class="form-label" for="revisionCode">
                      <span class="form-mandatory">*</span>
                      {{'jobReadiness.revisionCode' | translate}}
                    </label>
                    <span class="form-placeholder"> {{'createPopup.revisionCodeDesc' | translate}}</span>
                    <input type="text" formControlName="revisionCode" class="form-control"
                           [ngClass]="{ 'is-invalid': f.revisionCode.invalid && f.revisionCode.dirty }"
                           [ngbTypeahead]="searchRevisionCode" id="revisionCode"/>
                  </div>
                </div>
              </div>
              <div class="row d-flex m-lg-1">
                <div class="col-12">
                  <div class="form-group">
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox"
                             formControlName="creationConfirmation" id="creationConfirmation"/>
                      <label class="form-check-label" for="creationConfirmation">
                        {{'createPopup.correctRevision' | translate}} *
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
    </div>
    <div class="modal-footer gap-0">
        <button type="button" class="btn btn-secondary" (click)="activeModal.dismiss('Close click')">
            <span class="material-icons" aria-hidden="true">close</span>
            {{'createPopup.cancel' | translate}}
        </button>
        <button [disabled]="!isBtValidateActivate" type="submit" class="btn btn-primary">
          <span class="material-icons" aria-hidden="true">done</span>
          {{'createPopup.validate' | translate}}
      </button>
    </div>
</form>
